import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import ContentContainer from '../components/ContentContainer'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LayoutContainer from '../containers/layout'
import { Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import cisLogo from '../assets/CIS-logomark.svg'
import cisLogoFull from '../assets/CIS-logo.svg'
import baseTheme from '../styles/theme'
import theme from '../styles/theme'
import aboutImage from '../assets/SupportNetwork_about page.png'

export const query = graphql`
  query AboutPageQuery($language: String) {
    localizedSiteSettings: sanitySiteSettings(language: { eq: $language }) {
      id
      title
      language
      description
      aboutThisProject
      activityCorner
      aResourceSiteBy
    }

    localizedSections: allSanitySection(
      filter: { slug: { current: { ne: null } }, language: { eq: $language } }
    ) {
      edges {
        node {
          id
          _id
          language
          title
          slug {
            current
          }
        }
      }
    }

    localizedAbout: sanityAbout(language: { eq: $language }) {
      id
      title
      language
      _rawBody(resolveReferences: { maxDepth: 10 })
    }

    defaultAbout: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      language
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr [title] 4fr 1fr [content] 6fr 2fr',
    gap: baseTheme.custom.layout.gap,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  titleContainer: {
    gridColumn: 'title',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    gridColumn: 'content',
    display: 'grid',
    gridTemplateColumns: '[contentInner] 6fr [rightGutter] 2fr',
    overflow: 'auto',
  },
  contentInner: {
    gridColumn: 'content',
    paddingBottom: '1.5em',
  },
  logo: {
    width: '105%',
    height: 'auto',
    marginLeft: '-5%',
  },
  mobileLogoContainer: {
    marginTop: '1em',
    textAlign: 'center',
  },
}))

const AboutPage = (props) => {
  const { data, errors, pageContext } = props
  const classes = useStyles()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { localizedSiteSettings, localizedSections, localizedAbout, defaultAbout } = data
  const { language } = pageContext
  // console.log('AboutPage props', props)

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }

  const page = localizedAbout || defaultAbout
  // console.log('About page data', page)
  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <ThemeProvider theme={baseTheme}>
      <LayoutContainer
        language={language}
        sections={localizedSections}
        siteSettings={localizedSiteSettings}
      >
        <SEO title={page.title} />
        <ContentContainer className={classes.root}>
          <div className={classes.titleContainer}>
            <Typography variant="h1">{page.title}</Typography>
            {!mobile && <img className={classes.logo} src={aboutImage} />}
          </div>
          <div className={classes.contentWrapper}>
            <BlockContent className={classes.contentInner} blocks={page._rawBody || []} />
          </div>
          {mobile && (
            <div className={classes.mobileLogoContainer}>
              <img className={classes.logo} src={aboutImage} />
            </div>
          )}
        </ContentContainer>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default AboutPage
